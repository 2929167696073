

(function() {
  const attributeIdName = "data-vc-id";

  const ACTION_TYPE = {
    DELETE: "del",
    INSERT: "ins"
  };

  const CHANGE_REQUEST_TYPE = {
    DELETE_BLOCK: "DELETE_BLOCK",
    INSERT_BLOCK: "INSERT_BLOCK",
    DELETE_TEXT: "DELETE_TEXT",
    INSERT_TEXT: "INSERT_TEXT"
  };


  function ChangeRequest(jsonData) {
    this.Section = null;
    this.Offset = null;
    this.Length = null;
    this.Type = null;
    this.Html = null;
    this.Node = null;
    this.PreSection = null;
    this.PostSection = null;
    this.Block = null;
    this.BlockStyle = null;
    this.IsStatic = null;
    this.TableId = null;
    this.ListHeader = null;
    this.Nested = null;
    this.IsBullet = null;
    this.ListItemText = null;
    this.actionType = null;
    this.index = null;

    Object.keys(this).forEach(key => {
      this[key] = jsonData[key];
    });

    if (this.Type === ACTION_TYPE.INSERT) {
      if (this.Block) {
        this.actionType = CHANGE_REQUEST_TYPE.INSERT_BLOCK;
      } else {
        this.actionType = CHANGE_REQUEST_TYPE.INSERT_TEXT;
      }
    } else if (this.Type === ACTION_TYPE.DELETE) {
      if (this.Block) {
        this.actionType = CHANGE_REQUEST_TYPE.DELETE_BLOCK;
      } else {
        this.actionType = CHANGE_REQUEST_TYPE.DELETE_TEXT;
      }
    }

    this.cloneBlockVisibleHTML = function() {
      const block = this.getBlock();
      let clone = null;
      if (block && block.containingNode) {
        clone = block.containingNode.cloneNode(true);
        clone.querySelectorAll(".lp-hide-element").forEach(node => {
          node.remove();
        });
        clone.querySelectorAll("[free-text]").forEach(node => {
          node.removeAttribute("free-text");
        });
        clone.querySelectorAll("[contenteditable]").forEach(node => {
          node.removeAttribute("contenteditable");
        });
      }

      return clone;
    };

    const setupForDeleteBlock = () => {
      this.getBlock = () => {
        let returnValue;
        const element = document.querySelector(
          `[${attributeIdName}="${this.Section}"]`
        );

        if (!element) {
          this.unsupportedRequests.push(dataItem);
          returnValue = null;
        } else {
          returnValue = element.getBlockData();
        }
        return returnValue;
      };

      this.getPID = () => {
        const block = this.getBlock();
        if (block && block.containingNode) {
          return block.containingNode.getAttribute("pid");
        }
      };
    };

    if (this.actionType === CHANGE_REQUEST_TYPE.DELETE_BLOCK) {
      setupForDeleteBlock();
    }
  }

  function Annotation() {
    this.changes = [];
    this.anchor = null;
    this.type = null;
  }

  function VersionControlManager() {
    this.$scope = null;
    this.$rootScope = null;
    this.BlankLeaseService = null;
    this.ApiService = null;
    this.LeaseEditorService = null;
    this.eventName = "newVersionControl";
    this.modifiedFile = null;
    this.editorElement = null;
    this.viewElement = null;
    this.serverResponce = null;
    this.fullChangesList = [];
    this.supportedRequests = [];
    this.unsupportedRequests = [];
    this.changesViewElement = null;
    this.commentsElement = null;
    this.menuElement = null;
    this.annotations = {};
    

    this.enterVCView = function() {
      this.editorElement.classList.remove("open");
      this.viewElement.classList.add("review-changes");

      this.menuElement.classList.add("leasenavcollapsed");

      this.$scope.diffgram.on = true;
      this.$scope.leftPane = null;
      this.$scope.safeApply();
      this.changesViewElement = document.querySelector(".lease--modified");
      if (!this.commentsElement) {
        const el = document.createElement("comments");
        el.setAttribute("type", "accept-reject");
        this.changesViewElement.appendChild(el);
      }
    };
    this.exitVCView = function() {
      this.editorElement.classList.add("open");
      this.viewElement.classList.remove("review-changes");
      this.$scope.diffgram.on = false;
      this.$scope.leftPane = "deal-terms";

      this.menuElement.classList.remove("leasenavcollapsed");
      this.$scope.safeApply();
    };

    this.closeImportChangesDialog = function() {
      this.$rootScope.$emit("updateImportChangesDialogStatus", {
        close: close,
        noChanges: false,
        error: false
      });
    };

    const addDeleteMarkersForBlock = (dataItem, index) => {
      const block = dataItem.getBlock();

      const delElement = document.createElement("del");
      block.containingNode.parentElement.insertBefore(
        delElement,
        block.containingNode.nextElementSibling
      );
      block.moveToElement(delElement);
      delElement.setAttribute("data-vc-index", dataItem.index);
      delElement.setAttribute("data-vc-type", dataItem.actionType);
      delElement.classList.add("diff-item", "diff-item--deletion");
      this.supportedRequests.push(dataItem);
    };

    const createAnnotations = () => {
      const changes = document.querySelectorAll("[data-vc-type]");
      
      changes.forEach(change => {
        const index = change.getAttribute("data-vc-index");
        if (index) {
          const changeRequest = versionControlManager.fullChangesList[index];
          const pid = changeRequest.getPID();
          if (this.annotations[pid]) {
            console.log("t");
          } else {
            this.annotations[pid] = new Annotation();
            this.annotations[pid].changes.push(change);
          }
        } else {
          console.error(`could not find data for ${change}`);
        }
      });
    };

    const handleDataItem = (item, index) => {
      item.index = index;
      if (item.actionType == CHANGE_REQUEST_TYPE.DELETE_BLOCK) {
        addDeleteMarkersForBlock(item, index);
      }
    };

    this.proccesServerData = function(data) {
      data.forEach(handleDataItem);

      if (this.supportedRequests.length !== 0) {
        this.enterVCView();
        createAnnotations();
      }
      this.closeImportChangesDialog();
    };

    this.reciveServerResponce = function(response) {
      let arrData = [];
      versionControlManager.supportedRequests = [];
      versionControlManager.unsupportedRequests = [];
      versionControlManager.serverResponce = response;

      if (window.isDebug) {
        console.log("Version controll Server responce", response);
      }

      if (response && response.data) {
        response.data.forEach(item => {
          arrData.push(new ChangeRequest(item));
        });
      }

      versionControlManager.fullChangesList = arrData;

      versionControlManager.proccesServerData(arrData);
    };

    this.receiveDownloadFile = (event, data) => {
      this.ApiService.diffgram(
        data.file,
        this.modifiedFile,
        window.ASSETS_DESIRED_STYLES
      ).then(this.reciveServerResponce, function(error) {
        versionControlManager.closeImportChangesDialog();
        
        // $scope.showMask = false;
        // $scope.processing = false;
        // $scope.netWorkError = true;
        // console.log("error ", error);
      });
    };

    this.init = function(
      $scope,
      $rootScope,
      BlankLeaseService,
      ApiService,
      LeaseEditorService
    ) {
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.BlankLeaseService = BlankLeaseService;
      this.ApiService = ApiService;
      this.LeaseEditorService = LeaseEditorService;
      $scope.$on(this.eventName, this.receiveDownloadFile);
      this.$scope.diffgram = {};
      this.editorElement = document.querySelector(".full-editor");
      this.viewElement = document.querySelector(".preview");
      this.menuElement = document.querySelector(".lease-nav.md-menu-toolbar");
    };
    

    this.setIds = ()=> {      
      let spans = Array.from(
        document.querySelectorAll(".lease--original span[section-id] span,.lease--original span[section-id] a",true)
      );

      spans = spans.filter(s => {
        return (
          !s.classList.contains("EOCE") &&
          !s.classList.contains("SOCE") &&
          !s.hasAttribute("free-text") &&
          !s.hasAttribute("section-id")
        );
      });

      spans.forEach((span,index) => {
        if (!span.hasAttribute(attributeIdName)) {
          span.setAttribute(attributeIdName, index);
        }
      });
    };

    this.setIdsAndDownload = () =>{
      this.setIds();      
      downloadManager.prepareDownload("diffgramOriginal",this.$rootScope);
    }
  }
    
  window.versionControlManager = new VersionControlManager();
  
})();
